import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import Cookies from 'universal-cookie';
import { LandingHeader } from 'organisms/LandingHeader';
import { getSystemLang } from 'utils/getSystemLang';
import { Footer } from 'organisms';
import { getPathLang } from 'utils/getPathLang';

type LandingLayoutProps = {
  children?: React.ReactNode;
  isResume?: boolean;
};

export const LandingLayout = ({ children, isResume }: LandingLayoutProps) => {
  const [showBanner, setShowBanner] = useState(false);

  const router = useRouter();
  const cookies = new Cookies();
  const bannerShowPathArr = [
    '/',
    '/ko',
    '/ja',
    '/cn',
    '/es',
    '/grammar-check',
    '/paraphrase',
    '/translate',
    '/pricing',
    '/business',
    '/education'];
  const pathLang = getPathLang(router.pathname);

  let bannerPaddingDivHeight = '';

  switch (pathLang) {
    case 'en':
      bannerPaddingDivHeight = 'h-[105px] md:h-[69px] lg:h-[81px]';
      break;
    case 'ko':
      bannerPaddingDivHeight = 'h-[117px] md:h-[69px] lg:h-[81px]';
      break;
    case 'ja':
      bannerPaddingDivHeight = 'h-[117px] md:h-[71px] lg:h-[81px]';
      break;
    default:
      bannerPaddingDivHeight = 'h-[105px] md:h-[69px] lg:h-[81px]';
      break;
  }

  // the following CSS property is set to avoid text input area
  // being shadowed by header - https://github.com/pcanpi/web/issues/1452
  const resumeStyle = isResume
    ? 'max-h-screen overflow-auto scroll-pt-[48px] snap-y-mandatory md:scroll-pt-[64px]'
    : '';

  useEffect(() => {
    const acceptLangArr = window.navigator.languages.map(item => item.substring(0, 2));
    const getBrowserLang = getSystemLang(acceptLangArr) || 'en';
    if (
      typeof window !== 'undefined'
      && pathLang !== getBrowserLang
      && bannerShowPathArr.some(item => router.pathname.endsWith(item))
      && !cookies.get('no-lang-banner')
    ) {
      setShowBanner(true);
    } else {
      setShowBanner(false);
    }
  }, []);

  return (
    <div className={`h-fit min-h-screen relative ${resumeStyle}`}>
      <LandingHeader
        pathLang={pathLang}
        showBanner={showBanner}
        setShowBanner={setShowBanner}
      />
      {showBanner && <div className={bannerPaddingDivHeight} />}
      {children}
      <Footer pathLang={pathLang} />
    </div>
  );
};
