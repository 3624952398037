import React from 'react';
import { useRouter } from 'next/router';
import { Footer, Header } from 'organisms';

type Props = {
  children?: React.ReactNode;
  page?: string;
  isRealTime?: boolean;
  isResume?: boolean;
  isIosLessThan16?: boolean;
};

export const MainLayout = ({ children, page, isRealTime, isResume, ...props }: Props) => {
  const router = useRouter();
  const { pathname }: { pathname: string } = router;

  const isProductPage = pathname === '/proofread'
    || pathname === '/proofread-mb'
    || pathname === '/paraphrasing'
    || pathname === '/paraphrasing-mb'
    || pathname === '/translation';

  // the following CSS property is set to avoid text input area
  // being shadowed by header in Resume page
  // https://github.com/pcanpi/web/issues/1452
  const resumeSpecificStyle = isResume ? `max-h-screen overflow-auto
    snap-mandatory snap-y scroll-pt-48 md:scroll-pt-64` : '';

  const aboutPageStyle = page === 'about' ? 'flex' : '';

  return (
    <div
      className={`min-h-screen  relative bg-background ${resumeSpecificStyle}`}
      {...props}
    >
      <Header correctPage={isProductPage} isRealTime={isRealTime} />
      <main
        className={`min-h-[calc(100vh-275px)] pt-48 ${aboutPageStyle}
          md:pt-64 lg:min-h-screen`}
      >
        {children}
      </main>
      {!isProductPage && <Footer />}
    </div>
  );
};
