import { useRouter } from 'next/router';
import { Footer, Header } from 'organisms';

type Props = {
  children?: React.ReactNode;
  page?: string;
  isRealTime?: boolean;
  isIosLessThan16?: boolean;
};

export const MainLayoutMb = ({ children, page, isRealTime, isIosLessThan16, ...props }: Props) => {
  const router = useRouter();
  const { pathname } = router;
  const isCorrectPage = pathname === '/proofread'
    || pathname === '/proofread-mb'
    || pathname === '/paraphrasing'
    || pathname === '/paraphrasing-mb';

  const aboutPageStyle = page === 'about' ? 'flex' : '';

  return (
    <div
      className="w-screen min-h-screen fixed bg-background"
      {...props}
    >
      <Header correctPage={isCorrectPage} isRealTime={isRealTime} isIosLessThan16={isIosLessThan16} />
      <main
        className={`min-h-[calc(100vh-275px)] pt-48 ${aboutPageStyle}
          md:pt-64 lg:min-h-screen`}
      >
        {children}
      </main>
      {!isCorrectPage && <Footer />}
    </div>
  );
};
