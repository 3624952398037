import { langSeparateLandingArr } from 'constants/PATHNAME';

export const getPathLang = (pathname: string) => {
  let pathLang = '';

  if (langSeparateLandingArr.some(item => item === pathname)) {
    if (pathname.startsWith('/ko')) {
      // Korean page
      pathLang = 'ko';
    } else if (pathname.startsWith('/ja')) {
      // Japanese page
      pathLang = 'ja';
    } else if (pathname.startsWith('/cn')) {
      // Chinese page
      pathLang = 'cn';
    } else if (pathname.startsWith('/es')) {
      // Chinese page
      pathLang = 'es';
    } else {
      // English page (default)
      pathLang = 'en';
    }
  }

  return pathLang;
};
