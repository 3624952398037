import { MainLayout, LandingLayout } from 'components/layouts';

type ConditionalLayoutProps = {
  children: React.ReactNode;
  isToken: boolean;
  isResume?: boolean;
};

export const ConditionalLayout = ({ children, isToken, isResume, ...props }: ConditionalLayoutProps) => {
  // isResume is boolean used to set scroll top padding to avoid input being shadowed by header
  return (
    isToken
      ? <MainLayout isResume>{children}</MainLayout>
      : <LandingLayout isResume>{children}</LandingLayout>
  );
};
